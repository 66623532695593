import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chemical-peels',
  templateUrl: './chemical-peels.component.html',
  styleUrls: ['./chemical-peels.component.css']
})
export class ChemicalPeelsComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;

  ngOnInit() {
    this.title = 'Chemical Peels';
    this.description = `Buildup of excess oil, debris, makeup and dull skin cells can congest skin, increase acne
    and intensify pigmentation. In our youth, our natural rate of cell turnover and renewal is more frequent than
    in our late 30s, 40s, 50s and beyond. As this process slows, our skin can appear lackluster. Today’s chemical
    peel treatments help lift congestion and clear skin follicles. This clearing reduces blemishes and fine lines,
    evens out irregular pigmentation and stimulates collagen production.`;
    this.treatment = `Chemical peel treatments at Thornhill Dermatology Centre utilize advanced, customized
    formulations of glycolic (AHA), mandelic, trichloracetic orsalicylic (BHA) acids and potent antioxidants.
    Recommended for most skin types, chemical peels not only rejuvenate the complexion, they are also an excellent
    treatment for combatting acne, signs of aging and hyperpigmentation. Chemical Peel treatments are a superior
    solution for correcting and maintaining youthful skin tone and texture.`;
    this.whatToExpect = `Our chemical peel treatments begin with a thorough cleansing ritual. Next, the eyes, lips
    and nostrils are protected with a shielding petroleum. As the customized chemical peel formulation is gently
    applied, skin will feel warm and tingly. A blowing fan may be used to keep skin feeling cool. Patients are
    advised to discontinue topical retinoid applications and acne treatments 5-7 days prior to a chemical peel
    treatment. These topical treatments may be resumed as early as 5 days post treatment. Immediately following a
    chemical peel treatment, skin will feel fresh and appear slightly flushed. Within 2-3 days, mild dryness is
    commonly experienced. Over a recommended series of treatments, patients notice reduced fine lines, fewer
    blemishes, refined pore size, more even skin pigmentation and smoother skin texture.`;
    this.caption1 = 'What are Chemical Peels?';
    this.img = 'assets/images/chemical-peels-for-site.jpg';
    this.conclusion = `We are pleased to provide chemical peel treatments for Thornhill, Markham and surrounding
    communities. For further information, contact us today or request a complimentary consultation online.`;
  }

}
