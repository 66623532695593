import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skin-cancer',
  templateUrl: './skin-cancer.component.html',
  styleUrls: ['./skin-cancer.component.css']
})
export class SkinCancerComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;

  ngOnInit() {
    this.title = 'Skin Cancer';
    this.description = `<p>Skin cancer is the most common type of cancer; it is
    also one of the most preventable. Skin cancer is the uncontrolled growth of
    abnormal skin cells and is caused by overexposure to ultraviolet (UV) radiation.
    The three most common forms of skin cancers are basal cell carcinoma, squamous
    cell carcinoma and malignant melanoma, each named after the skin cell they affect.</p>
   <p>Basal Cell Carcinoma is the most common form of skin cancer (accounting for 90%)
     and starts in the basal cells of the epidermis. This type of skin cancer is caused
     by long-term sun exposure and is most easily treated.</p><p>Squamous Cell Carcinoma
     is the second most common form of skin cancer developing in the epidermis, eventually
     penetrating the underlying tissues. Squamous cell can develop from untreated actinic
     keratosis (sun damage) and is known to spread quickly if left untreated.</p><p>Malignant
     Melanoma is the most serious form of skin cancer and is also responsible for most skin
     cancer-related deaths in Canada. Melanoma can develop from a pre-existing mole or as an
     irregular new skin discolouration. When detected early, melanoma has one of the highest
     cure rates (greater than 90%).</p><p>Since skin cancer does not discriminate between age
     (second most common cancer in young adults 15-34), skin type or race, early detection and
     treatment is key. Skin cancer is both preventable and treatable – get checked today!</p>`;
    this.treatment = `At Thornhill Dermatology Centre, we are proud to provide medical-grade interventions
    for effective skin cancer treatment. We provide a variety of treatment solutions for skin cancer
    including surgical removal, cryosurgery (liquid nitrogen) and skincare. Our dermatologists provide a
    clinical diagnosis to ensure the most effective treatment solutions are proposed and reviewed.`;
    this.whatToExpect = `To adequately address dermatological concerns, we welcome patients by referral. Our
    board-certified dermatologists review your personal and familial skin history and thoroughly assess and
    diagnose your condition. Treatment and recovery time will vary by procedure. Care instructions will be
    thoroughly reviewed prior to treatment. Medical referrals and treatments at Thornhill Dermatology Centre
    are covered through OHIP.`;
    this.caption1 = 'What is Skin Cancer?';
    this.img = 'assets/images/skin-cancer-for-site.jpg';
    this.conclusion = `We are pleased to provide customized treatments for skin cancer for Thornhill, Markham
    and surrounding communities. For an appointment, have your family physician fax a referral to (905) 695-3800.
    Once received, we will contact you with appointment details.
`;
  }

}
