import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eczema',
  templateUrl: './eczema.component.html',
  styleUrls: ['./eczema.component.css']
})
export class EczemaComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;

  ngOnInit() {
    this.title = 'Eczema';
    this.description = `Eczema, also referred to as Dermatitis, is a type of skin inflammation characterized
     by red, itchy and swollen skin. Atopic Dermatitis (hereditary) and Contact Dermatitis (allergic and
      irritant) are the most common types of eczema. Eczema most commonly appears behind the knees, inside
       of the elbows and on the face, neck and hands. Flare-ups are most common during the winter months
        when skin is at its driest. Eczema is a common skin concern and like rosacea, can only be managed,
         not cured.`;
    this.treatment = 'At Thornhill Dermatology Centre, we are proud to provide medical-grade interventions for effective eczema treatment. We provide a variety of eczema solutions including medication, skincare and trigger avoidance coaching. Our dermatologists provide a clinical diagnosis to ensure the most effective treatment solutions are proposed and reviewed.';
    this.whatToExpect = 'To adequately address dermatological concerns, we welcome patients by referral. Our board-certified dermatologists review your personal and familial skin history and thoroughly assess and diagnose your condition. Treatment and recovery time will vary by procedure. Care instructions will be thoroughly reviewed prior to treatment. Medical referrals and treatments at Thornhill Dermatology Centre are covered through OHIP.';
    this.caption1 = 'What is Eczema?';
    this.img = 'assets/images/eczema-for-site.jpg';
    this.conclusion = `We are pleased to provide customized treatments for eczema for Thornhill,
    Markham and surrounding communities. For an appointment, have your family physician fax a
    referral to (905) 695-3800. Once received, we will contact you with appointment details.
`;

  }

}
