import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rosacea',
  templateUrl: './rosacea.component.html',
  styleUrls: ['./rosacea.component.css']
})
export class RosaceaComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;

  ngOnInit() {
    this.title = 'Rosacea';
    this.description = `Rosacea, also known as acne rosacea, is a chronic inflammatory skin condition
     affecting over 2 million Canadians. Rosacea is most common among fair skinned individuals between the
     ages of 30 and 50; however, it can affect any skin type. Often misdiagnosed as “adult acne,” rosacea
     can present as tiny pus-filled pimples and bumps: giving the skin a rough and uneven texture. In
     progressive cases, rosacea can lead to prominent facial blood vessels, eye trouble and a bulbous nose
     (Rhinophyma). Rosacea, unlike some skin conditions, does not improve or resolve on its own, and untreated
     rosacea can worsen over time. Accurate diagnosis and timely treatment is imperative for successful outcomes.
     Effective control and management of rosacea symptoms has been shown to improve both the mental and emotional
     well-being of rosacea patients.`;
    this.treatment = `At Thornhill Dermatology Centre, we are proud to provide medical-grade interventions
    for effective rosacea treatment. We provide a variety of rosacea management options including oral
    medications, topical agents, skincare and trigger avoidance coaching. Our dermatologists provide a
    clinical diagnosis to ensure the most effective treatment solutions are proposed and reviewed.`;
    this.whatToExpect = `To adequately address dermatological concerns, we welcome patients by referral. Our
    board-certified dermatologists review your personal and familial skin history and thoroughly assess and
    diagnose your condition. Treatment and recovery time will vary by procedure. Care instructions will be
    thoroughly reviewed prior to treatment. Medical referrals and treatments at Thornhill Dermatology Centre
    are covered through OHIP.`;
    this.caption1 = 'What is Rosacea?';
    this.img = 'assets/images/rosacea-for-site.jpg';
    this.conclusion = `We are pleased to provide customized treatments for rosacea for Thornhill, Markham
    and surrounding communities. For an appointment, have your family physician fax a referral to (905) 695-3800.
    Once received, we will contact you with appointment details.
`;
  }

}
