import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cosmetic-products-and-services',
  templateUrl: './cosmetic-products-and-services.component.html',
  styleUrls: ['./cosmetic-products-and-services.component.css']
})
export class CosmeticProductsAndServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
