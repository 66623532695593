import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dermal-fillers',
  templateUrl: './dermal-fillers.component.html',
  styleUrls: ['./dermal-fillers.component.css']
})
export class DermalFillersComponent implements OnInit {

  constructor() {
  }

  title: string;
  caption1: string;
  description: string;
  treatment: string;
  whatToExpect: string;
  img: string;
  conclusion: string;

  ngOnInit() {

    this.title = 'Dermal Fillers';
    this.description = `We are born with plentiful facial volume created in part by healthy stores of hyaluronic
    acid and youthful connective fibers like collagen and elastin. Over time, these stores slowly deplete and
    connective tissues degrade, leading to sunken or deflated features and apparent skin laxity. This degradation
    is further accelerated by sun exposure. Today’s natural dermal fillers help restore lost volume for instant and
    long lasting facial rejuvenation.`;
    this.treatment = `Thornhill Dermatology Centre offers dermatologist-provided dermal filler treatments. Utilizing
    leading hyaluronic acid-based filler options and advanced injection techniques, we ensure a natural, long lasting
    result performed with optimal safety. Dermal filler treatments are ideal for restoring volume to the cheeks,
    temples, under-eye hollows, deep wrinkles and folds. In addition, dermal filler treatments can subtly enhance
    the lip for fewer lip lines and soft volume. The experience of your provider dictates overall treatment
    satisfaction. Our patients benefit from specialist care and years of continuing education for an outcome that
    is subtle and natural.`;
    this.whatToExpect = `Following a complimentary consultation and assessment, your dermal filler treatment will
    be provided by one of our dermatologists. An integrated anesthetic will ensure the experience is comfortable.
    Results are instant and improve over the coming weeks as the hyaluronic acid attracts and binds water for soft
    volume. A follow-up appointment will be arranged before you leave the office. At that time, we will assess the
    progression of your result and touch-up as desired for a flawless outcome. Your result will last upwards of one
    year and can be optimally maintained with advanced skincare products and neuromodulators such as BOTOX COSMETIC®
    treatments.`;
    this.caption1 = 'What are Dermal Fillers?';
    this.img = 'assets/images/dermal-fillers-for-site.jpg';
    this.conclusion = `We are pleased to provide dermal filler treatments for Thornhill, Markham and surrounding
    communities. For further information, contact us today or request a complimentary consultation online.`;
  }
}
