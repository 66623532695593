import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-tdc',
  templateUrl: './why-tdc.component.html',
  styleUrls: ['./why-tdc.component.css']
})
export class WhyTdcComponent  {

  constructor() { }


}
