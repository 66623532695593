import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CovidUpdateComponent } from './covid-update/covid-update.component';
import {RouterModule} from '@angular/router';
import {ControlContainer, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { WhyTdcComponent } from './why-tdc/why-tdc.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { AboutComponent } from './about/about.component';
import { ChemicalPeelsComponent } from './chemical-peels/chemical-peels.component';
import { DermalFillersComponent } from './dermal-fillers/dermal-fillers.component';
import { MicroDermComponent } from './micro-derm/micro-derm.component';
import { NeuroModulatorsComponent } from './neuro-modulators/neuro-modulators.component';
import { FacialsComponent } from './facials/facials.component';
import { SkincareComponent } from './skincare/skincare.component';
import { SkinTagsAndMoleRemovalComponent } from './skin-tags-and-mole-removal/skin-tags-and-mole-removal.component';
import { VenusVivaComponent } from './venus-viva/venus-viva.component';
import { VisiaComponent } from './visia/visia.component';
import { AcneComponent } from './acne/acne.component';
import { EczemaComponent } from './eczema/eczema.component';
import { PsoriasisComponent } from './psoriasis/psoriasis.component';
import { RosaceaComponent } from './rosacea/rosacea.component';
import { SkinCancerComponent } from './skin-cancer/skin-cancer.component';
import { SunDamageComponent } from './sun-damage/sun-damage.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MedicalLayoutComponent } from './medical-layout/medical-layout.component';
import { OurTeamDetailsComponent } from './our-team-details/our-team-details.component';
import {ModalModule, ModalService} from "./_modal";
import { HomePageSpecialsComponent } from './home-page-specials/home-page-specials.component';
import { MoleMappingComponent } from './mole-mapping/mole-mapping.component';
import { CosmeticProductsAndServicesComponent } from './cosmetic-products-and-services/cosmetic-products-and-services.component';

@NgModule({
  declarations: [
    AppComponent,
    CovidUpdateComponent,
    HomeComponent,
    WhyTdcComponent,
    OurTeamComponent,
    AboutComponent,
    ChemicalPeelsComponent,
    DermalFillersComponent,
    MicroDermComponent,
    NeuroModulatorsComponent,
    FacialsComponent,
    SkincareComponent,
    SkinTagsAndMoleRemovalComponent,
    VenusVivaComponent,
    VisiaComponent,
    AcneComponent,
    EczemaComponent,
    PsoriasisComponent,
    RosaceaComponent,
    SkinCancerComponent,
    SunDamageComponent,
    ContactUsComponent,
    MedicalLayoutComponent,
    OurTeamDetailsComponent,
    HomePageSpecialsComponent,
    MoleMappingComponent,
    CosmeticProductsAndServicesComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
  ModalModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'acne', component: AcneComponent },
      { path: 'chemical-peels', component: ChemicalPeelsComponent },
      { path: 'eczema', component: EczemaComponent },
      { path: 'psoriasis', component: PsoriasisComponent },
      { path: 'rosacea', component: RosaceaComponent },
      { path: 'skin-cancer', component: SkinCancerComponent },
      { path: 'sun-damage', component: SunDamageComponent},
      { path: 'our-team', component: OurTeamComponent},
      { path: 'our-team-details/:initials', component: OurTeamDetailsComponent},
      { path: 'dermal-fillers', component: DermalFillersComponent},
      { path: 'facials', component: FacialsComponent},
      { path: 'micro-derm', component: MicroDermComponent},
      { path: 'neuro-modulators', component: NeuroModulatorsComponent},
      { path: 'skin-tags-and-mole-removal', component: SkinTagsAndMoleRemovalComponent},
      { path: 'venus-viva', component: VenusVivaComponent},
      { path: 'visia', component: VisiaComponent},
      { path: 'covid-update', component: CovidUpdateComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'skincare', component: SkincareComponent },
      { path: 'why-tdc', component: WhyTdcComponent },
      { path: 'home-page-specials', component: HomePageSpecialsComponent },
      { path: 'mole-mapping', component: MoleMappingComponent },
      { path: 'cosmetic-products-and-services', component: CosmeticProductsAndServicesComponent },
    ],{ scrollPositionRestoration: 'enabled'})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
