import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-medical-layout',
  templateUrl: './medical-layout.component.html',
  styleUrls: ['./medical-layout.component.css']
})
export class MedicalLayoutComponent implements OnInit {

  @Input('title') title='?';
  @Input('caption1') caption1='?';
  @Input('description') description='?';
  @Input('treatment') treatment='?';
  @Input('whatToExpect') whatToExpect='?';
  @Input('img') img=null;
  @Input('img2') img2=null;

  @Input('conclusion') conclusion='?';

  constructor() { }

  ngOnInit() {
  }

}
