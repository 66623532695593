import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-micro-derm',
  templateUrl: './micro-derm.component.html',
  styleUrls: ['./micro-derm.component.css']
})
export class MicroDermComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;

  ngOnInit() {

    this.title = 'Microdermabrasion';
    this.description = `In as early as our late teenage years, our natural ability for exfoliation and skin cell
    renewal begins to slow; dull skin cells tend to build up on the skin’s surface and effect the appearance of
    our complexion. We see an increase in hyperpigmentation, congested pores, blemishes and rough, wrinkled skin
    texture. Microdermabrasion treatments help lift these outermost skin cells for clearer, more youthful skin
    while also stimulating the skin’s ability for rejuvenation.`;
    this.treatment = `Microdermabrasion treatments at Thornhill Dermatology Centre employ the SilkPeel® dermalinfusion
     system. A diamond-embedded tip glides across the skin and exfoliates the outer most layer (stratum corneum).
     This makes the skin more receptive to the unique, second part of this microdermabrasion treatment, which involves
     the application of  a tailored treatment solution for skin rejuvenation, brightening or for combatting acne and
     blemishes. Over a series of recommended treatments, SilkPeel® microdermabrasion treatments help reduce fine lines,
     even irregular pigment, minimize acne breakouts and smooth skin texture. Treatments are recommended for most
     skin types.`;
    this.whatToExpect = `Following a complimentary consultation and assessment, SilkPeel® microdermabrasion
    treatments are provided by our experienced medical estheticians. The treatment begins with a deep cleansing ritual.
    Treatment levels are tailored to optimize comfort and maximize outcomes. Immediately following treatment, skin
    feels soft and supple. Patients may return immediately to regular activities. Depending on the customized topical
    infusion, patients may experience mild dryness in the days following treatment. Treatment frequency is tailored to
    individual objectives and lifestyle.`;
    this.caption1 = 'What is Microdermabrasion?';
    this.img = 'assets/images/microderm-for-site.jpg';
    this.conclusion = `We are pleased to provide microdermabrasion treatments for Thornhill, Markham and surrounding
    communities. For further information, contact us today or request a complimentary consultation online.`;
  }

}
