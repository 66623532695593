import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-neuro-modulators',
  templateUrl: './neuro-modulators.component.html',
  styleUrls: ['./neuro-modulators.component.css']
})
export class NeuroModulatorsComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;


  ngOnInit() {

    this.title = 'Neuromodulators';
    this.description = `With time, repeated expressions and the degradation of youthful skin structures give way to
    lines and wrinkles. Beginning as soft lines and eventually settling into deep wrinkles, these not so “fine”
    lines indicate unmistakable signs of aging and can give an unwanted angry or tired appearance. Today, with
    revolutionary advancements in aesthetic medicine, there is no need to dread the appearance of unwanted wrinkles;
    we are now able to celebrate easy, temporary and natural treatment solutions.`;
    this.treatment = `Neuromodulators such as BOTOX COSMETIC®, Xeomin® and Dysport® treatments have become the world’s
    most popular cosmetic intervention since makeup. By settling in to soften wrinkle-causing muscle movements,
    neuromodulators temporarily relieve the skin of unwanted frown lines, crow’s feet, eye wrinkles and more!
    Advanced neuromodulator treatments by our experienced physicians may also raise the eyebrows, turn up the
    corners of the mouth and smooth dimpling around the chin. Therapeutic applications for neuromodulators can also
    relieve excessive sweating of the underarms, palms and soles of the feet.`;
    this.whatToExpect = `Following a complimentary consultation and assessment, neuromodulator treatments at
    Thornhill Dermatology Centre are provided by one of our experienced, board-certified dermatologists. The
    treatment itself requires approximately 10 minutes, after which patients may return to their regular activities.
    Results reveal themselves gradually between 3 days and 2 weeks following treatment. When expertly provided,
    expression and sensation are preserved for a natural, refreshed outcome that lasts for 3-5 months.`;
    this.caption1 = 'What are Neuromodulators?';
    this.img = 'assets/images/neuro-modulators-for-site.jpg';
    this.conclusion = `We are pleased to provide neuromodulator treatments for Thornhill, Markham and surrounding
    communities. For further information, contact us today or request a complimentary consultation online.`;
  }

}
