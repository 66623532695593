import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-venus-viva',
  templateUrl: './venus-viva.component.html',
  styleUrls: ['./venus-viva.component.css']
})
export class VenusVivaComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;


  ngOnInit() {

    this.title = 'Venus Viva™';
    this.description = `It’s no secret, as we age our skin gradually loses volume and the firmness of our youth slowly
    diminishes. Where surgical facelifts were once the only option for treating skin laxity, patients are now
    relishing the opportunity for facial and body skin tightening without surgery. Advanced, non-invasive Venus Viva™
     works uniquely on skin structures to prompt a natural stimulation and tightening response – resulting in a
     restored, more youthful appearance.`;
    this.treatment = `Venus Viva™ treatments employ patented NanoFractional Radio Frequency™ (RF) technology to
    target and heat the deeper layers of the skin. This heating creates targeted, microscopic thermal zones, which
    are quickly healed by the surrounding untreated tissues. The disruption and subsequent healing tightens skin,
    improves both texture and tone and has also been shown to diminish acne scars, fine lines and wrinkles. This
    treatment is safe for all skin types and has been shown to improve the penetration ability of certain topical
    agents, therefore increasing their efficacy. The patented SmartScan™ feature allows for quick application and
    improved comfort.`;
    this.whatToExpect = `Following a complimentary consultation and assessment, your Venus Viva™ treatment will be
    provided by one of our medical estheticians. Topical anesthetic is usually not required, however is available
    upon request. Treatment times vary depending on the treatment area and recovery time is minimal. Results are
    gradual, with full benefit realized after 6-12 months.`;
    this.caption1 = 'What is Venus Viva™?';
    this.img = 'assets/images/venus-viva-for-site.jpg';
    this.conclusion = `We are pleased to provide Venus Viva™ treatments to residents of Concord, Toronto, Vaughan, Mississauga, Thornhill, Markham and surrounding
    communities. Contact us today to request a complimentary consultation or to book an appointment.`;
  }

}
