import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mole-mapping',
  templateUrl: './mole-mapping.component.html',
  styleUrls: ['./mole-mapping.component.css']
})
export class MoleMappingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
