import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acne',
  templateUrl: './acne.component.html',
  styleUrls: ['./acne.component.css']
})
export class AcneComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  img2 : string;
  conclusion : string;

  ngOnInit() {
    this.title = 'Acne';
    this.description = `Considered the most commonly treated skin condition by dermatologists,
    acne affects over 20% of the Canadian population (roughly 5.6 million people).
    Acne (Acne Vulgaris) is a skin disorder characterized by excess sebum (oil) production,
    comedones (blackheads and whiteheads), pustules, nodules and cysts.
    Acne is most commonly found on the face but it can also target areas of the jawline, chest and back.
    Scarring is an unfortunate result of even the mildest acne cases so early intervention is key.
    While acne is a common skin disorder during adolescence, it can remain well into adulthood.`;
    this.treatment = `At Thornhill Dermatology Centre, we are proud to provide medical-grade interventions
    for effective acne treatment. We provide a variety of acne and acne scarring solutions including
    microdermabrasion, chemical peels, cyst excision, medication and skincare. Our dermatologists provide a
    clinical diagnosis to ensure the most effective treatment solutions are proposed and reviewed.`;
    this.whatToExpect = `To adequately address dermatological concerns, we welcome patients by referral.
    Our board-certified dermatologists review your personal and familial skin history and thoroughly assess
    and diagnose your condition. Treatment and recovery time will vary by procedure. Care instructions
    will be thoroughly reviewed prior to treatment. Medical referrals and treatments at Thornhill Dermatology
    Centre are covered through OHIP.`;
    this.caption1 = 'What is Acne?';
    this.img = 'assets/images/acne-for-site.jpg';
    this.conclusion = `We are pleased to provide customized treatments for acne for Thornhill, Markham and
     surrounding communities. For an appointment, have your family physician fax a referral to (905) 695-3800.
     Once received, we will contact you with appointment details.
`;
  }

}
