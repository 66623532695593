import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facials',
  templateUrl: './facials.component.html',
  styleUrls: ['./facials.component.css']
})
export class FacialsComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;
  ngOnInit() {

    this.title = 'Facials';
    this.description = `The secret to celebrities’ radiant skin: oxygen. Day to day, busy lives can lead to skin
    neglect. Few can afford downtime from work and home life and so, much like our celebrity skin idols, we require
    timely, effective solutions. On a regular basis or prior to any kind of special event, oxygenating facial
    treatments breathe life back into dull skin without any need for recovery.`;
    this.treatment = `Skin dehydration leads to dullness and can exacerbate lines, wrinkles and blemishes. Oxygenating
    facial treatments are recommended for all skin types to instantly hydrate and make the skin appear smoother and
    plumper. As essential moisture is pressurized into thirsty skin, the skin’s natural moisture barrier is restored
    and skin feels and appears more balanced. Whether experiencing a recommended series of treatments or a quick
    refresh of the complexion prior to a special event or engagement, results of oxygenating facials are enjoyed and
    noticed instantly!`;
    this.whatToExpect = `Following a complimentary consultation and assessment, an oxygenating facial treatment
    at Thornhill Dermatology Centre is provided by an experienced medical esthetician. The facial begins with a
    horough cleansing ritual. Next, your treatment provider employs pressurized oxygen to spray atomized moisturizers
    onto the skin for optimal absorption. The treatment experience is enjoyable and requires no more than 15 to
    30 minutes. Instantly, skin appears well hydrated and plump. A series of treatments helps to keep fine lines
    at bay and to maintain healthy moisture levels for more resilient and youthful skin.`
    this.caption1 = 'What are Facials?';
    this.img = 'assets/images/facials-for-site.jpg';
    this.conclusion = `Oxygenating facials may be performed alone or in combination with microdermabrasion. Find out
    what’s right for you at Thornhill Dermatology Centre. We are pleased to provide oxygenating facial treatments for
    Thornhill, Markham and surrounding communities. For further information, contact us today or request a
    complimentary consultation online.`;
  }
}
