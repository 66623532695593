import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skin-tags-and-mole-removal',
  templateUrl: './skin-tags-and-mole-removal.component.html',
  styleUrls: ['./skin-tags-and-mole-removal.component.css']
})
export class SkinTagsAndMoleRemovalComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;


  ngOnInit() {

    this.title = 'Skin Tag & Mole Removal';
    this.description = `Smooth skin can enhance confidence. For any number of reasons, we are prone to developing
    freckles, moles, skin tags and odd lumps and bumps, which can detract from a clear complexion. Thankfully,
    bothersome spots and bumps need not consume another moment of attention or worry. Tailored dermatological
    solutions can quickly and effectively minimize or remove your troubling imperfections.`;
    this.treatment = `At Thornhill Dermatology Centre, we are proud to provide medical-grade interventions for
    effective spot removal. Unwanted moles and scaly spots may be flattened or removed through specialized
    cryotherapy or surgical techniques. Skin tags and oil glands may be cauterized and irregular skin texture
    may be improved through a tailored series of chemical peels or microdermabrasion treatments. Our dermatologists
    provide a clinical diagnosis to ensure the most effective treatment solutions are proposed and reviewed.`;
    this.whatToExpect = `Following a complimentary consultation and assessment, your care providers at Thornhill
    Dermatology Centre will review optimal treatment solutions to suit your lifestyle and objectives. Treatments
    may incur some recovery such as redness, scabbing and sloughing. Sun protection will be imperative for long-term
    maintenance and in some instances, treatments may be repeated. Care instructions will be thoroughly reviewed
    prior to treatment and a follow-up appointment will be recommended.`;
    this.caption1 = 'What is Skin Tag & Mole Removal?';
    this.img = 'assets/images/skin-tags-and-mole-removal-for-site.jpg';
    this.conclusion = `We are pleased to provide customized treatments for spots and bumps for Thornhill, Markham
    and surrounding communities. For further information, contact us today or request a complimentary consultation
    online.`;
  }

}
