import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skincare',
  templateUrl: './skincare.component.html',
  styleUrls: ['./skincare.component.css']
})


export class SkincareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
