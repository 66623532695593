import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-psoriasis',
  templateUrl: './psoriasis.component.html',
  styleUrls: ['./psoriasis.component.css']
})
export class PsoriasisComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;

  ngOnInit() {
    this.title = 'Psoriasis';
    this.description = `Psoriasis is a common chronic inflammatory skin condition that is characterized by
    elevated red patches and flaking silvery scales. These skin lesions vary in size and are known to be
    itchy and sometimes painful. While lesions can appear anywhere on the body, the most common sites
    include elbows, knees, chest, scalp and lower back. Affecting over 1 million Canadians, psoriasis can
    have a lasting negative impact on self-esteem and social confidence.`;
    this.treatment = `At Thornhill Dermatology Centre, we are proud to provide medical-grade interventions
    for effective psoriasis treatment. We provide a variety of psoriasis solutions including oral
    medications (systemic), topical skincare and trigger avoidance coaching. Our dermatologists provide a
    clinical diagnosis to ensure the most effective treatment solutions are proposed and reviewed.`;
    this.whatToExpect = `To adequately address dermatological concerns, we welcome patients by referral.
    Our board-certified dermatologists review your personal and familial skin history and thoroughly assess
    and diagnose your condition. Treatment and recovery time will vary by procedure. Care instructions will
    be thoroughly reviewed prior to treatment. Medical referrals and treatments at Thornhill Dermatology
    Centre are covered through OHIP.`;
    this.caption1 = 'What is Psoriasis?';
    this.img = 'assets/images/psoriasis-for-site.jpg';
    this.conclusion = `We are pleased to provide customized treatments for psoriasis for Thornhill, Markham
    and surrounding communities. For an appointment, have your family physician fax a referral to (905) 695-3800.
    Once received, we will contact you with appointment details.
`;

  }

}
