import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalService} from "./_modal";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'thornhillderm.com';
  constructor(private modalService: ModalService, private activatedRoute: Router) {
  }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    this.openModal('custom-modal-1');
  }

  openModal(id:string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  closeModalAndNavigateToCovid(id: string) {
    this.modalService.close(id);
    this.activatedRoute.navigate(['/covid-update']);
  }

getYear() : number{
  return (new Date()).getFullYear();
}

}
