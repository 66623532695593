import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})
export class OurTeamComponent implements OnInit {

  constructor() { }
  data = [
    {
      name: "Lori Shapiro",
      biline: "MD, FRCPC, Dermatologist",
      url  : "/assets/images/shapiro.jpg",
      initials : "LS"
    },
    {
      name: "Howard Bargman",
      biline: "MD, FRCPC, Dermatologist",
      url  : "/assets/images/bargman.jpg",
      initials : "HB"
    },
    {
      name: "Sandra Landolt",
      biline: "MD, FRCPC, Dermatologist",
      url  : "/assets/images/landolt.jpg",
      initials : "SL"
    },
    {
      name: "Joel DeKoven",
      biline: "MD, MHSC, FRCPC, Dermatologist",
      url  : "/assets/images/dekoven.jpg",
      initials : "JD"

    },
    {
      name: "Michael Brandt",
      biline: "MD, FRCSC, Reconstructive Surgeon",
      url  : "/assets/images/brandt.jpg",
      initials : "MB"
    },
    {
      name: "Malika Ladha",
      biline: "MD, FRCPC, Dermatologist",
      url  : "/assets/images/ladha.jpg",
      initials : "ML"
    },
    {
      name: "Sheila Wang",
      biline: "MD, PhD, FRCPC, Dermatologist",
      url  : "/assets/images/wang.jpg",
      initials : "SW"
    }

    // {
    //   name: "Jill Greenspoon",
    //   biline: "MD, FRCPC, Dermatologist",
    //   url  : "/assets/images/greenspoon.jpg",
    //   initials : "JG"
    // }
    /*{
      name: "John Gencarelli",
      biline: "MD, FRCSC, Plastic Surgeon",
      url  : "/assets/images/Gencarelli.jpg",
      initials : "JGe"
    } */
  ];
  ngOnInit() {
  }

}
