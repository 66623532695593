import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sun-damage',
  templateUrl: './sun-damage.component.html',
  styleUrls: ['./sun-damage.component.css']
})
export class SunDamageComponent implements OnInit {

  constructor() { }
  title : string;
  caption1 : string;
  description : string;
  treatment : string;
  whatToExpect : string;
  img : string;
  conclusion : string;

  ngOnInit() {
    this.title = 'Sun Damage';
    this.description = `It is estimated that ultraviolent light (UV) is responsible for 90% of skin changes
    and visible signs of aging. Sun damage, also known as photoaging, not only leads to premature aging
    (including wrinkles, brown spots (solar lentigines), dilated veins and skin discolouration), it can
    also produce precancerous rough red spots called actinic keratosis. These pre-cancerous lesions require
    immediate intervention since untreated lesions carry the risk of developing into squamous cell carcinoma
    (the second most common form of skin cancer). Sun damage treatment is imperative since skin lesions have
    a high growth rate and are known to spread, thus damaging the surrounding healthy tissue.`;
    this.treatment = `At Thornhill Dermatology Centre, we are proud to provide medical-grade interventions
    for effective sun damage and actinic keratosis treatment. We provide a variety of treatment solutions
    for sun damage including topical agents, surgical removal, laser therapies, cryosurgery (liquid nitrogen)
     and skincare. Our dermatologists provide a clinical diagnosis to ensure the most effective treatment
     solutions are proposed and reviewed.`;
    this.whatToExpect = `To adequately address dermatological concerns, we welcome patients by referral.
    Our board-certified dermatologists review your personal and familial skin history and thoroughly assess
    and diagnose your condition. Treatment and recovery time will vary by procedure. Care instructions will
    be thoroughly reviewed prior to treatment. Medical referrals and treatments at Thornhill Dermatology
    Centre are covered through OHIP.`;
    this.caption1 = 'What is Sun Damage?';
    this.img = 'assets/images/sun-damage-for-site.jpg';
    this.conclusion = `We are pleased to provide customized treatments for sun damage for Thornhill, Markham
    and surrounding communities. For an appointment, have your family physician fax a referral to (905) 695-3800.
    Once received, we will contact you with appointment details.
`;
  }

}
