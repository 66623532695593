import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-our-team-details",
  templateUrl: "./our-team-details.component.html",
  styleUrls: ["./our-team-details.component.css"],
})
export class OurTeamDetailsComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  initials: string = "LS";
  data = {
    LS: {
      name: "Dr. Lori Shapiro",
      biline: "MD, FRCPC, Dermatologist",
      description: `<p>Founder of Thornhill Dermatology Centre in 2009, Dr. Lori Shapiro is dedicated to bringing
        expert skin care to Thornhill, Markham and the surrounding communities. With over 18 years of practical
        experience, Dr. Shapiro excels within all facets of dermatology. Her extensive training in Dermatology,
        Internal Medicine and Clinical Pharmacology makes her an ideal physician for dermatological practice. In
        addition to her role at Thornhill Dermatology Centre, Dr. Shapiro is currently a member of the division of
        Dermatology and Clinical Pharmacology at Sunnybrook Health Science Centre in Toronto, Ontario.</p><p>Born and
        raised in Winnipeg, Manitoba, Dr. Shapiro earned her medical degree from the University of Manitoba.
        Continuing her medical education with the University of Manitoba, she completed her residency in Internal
        Medicine in 1992. Leaving Manitoba, Dr. Shapiro attended Dalhousie University in Halifax, Nova Scotia where
        she completed a second residency in Dermatology. On the wake of a successful dermatology residency (where she
        was awarded the Resident Teaching Award in Undergraduate Medicine), Dr. Shapiro accepted a prestigious
        fellowship in Clinical Pharmacology at the University of Toronto. Her successful completion garnered her the
        Bill Mahon Resident Research Award in Clinical Pharmacology.</p><p>Dr. Lori Shapiro is an accomplished
        researcher and leading skin expert. With over 30 publications to her name, Dr. Shapiro is seen as the research
        authority for her work examining adverse drug reactions in the skin. An Assistant Professor with the
        Department of Medicine at the University of Toronto, Dr. Shapiro is committed to the education and development
        of Toronto’s newest medical students. When she isn’t practicing, teaching, or researching, Dr. Shapiro enjoys
        cooking, running and reading.<p><strong>Dr. Shapiro’s professional memberships include the following:</strong>
        </p> <ul><li>Fellow of the Royal College of Physicians and Surgeons of Canada</li><li>Member of the American
        Academy of Dermatology</li><li>Member of the Canadian Dermatology Association</li><li>Member of the Ontario
        Medical Association</li><li>Member of the Toronto Dermatology Society</li></ul>`,
      qualifications: `<p class='my-0 black'><strong>DERMATOLOGY, INTERNAL MEDICINE, CLINICAL PHARMACOLOGY</strong></p><br>
        <p class='my-0 black'><strong>ASSISTANT PROFESSOR OF MEDICINE</strong></p>
        <p class='my-0'>University of Toronto</p><br>
        <p class="my-0 black"><strong> CONSULTANT DERMATOLOGIST</strong></p>
        <p class="my-0">Sunnybrook Health Sciences Centre</p> <br>
        <p class="my-0 black"><strong>CONSULTANT CLINICAL PHARMACOLOGIST </strong></p>
       <p class="mb-0"> Drug Safety Clinic, Sunnybrook Health Sciences Centre</p>`,
      img: "assets/images/big_shapiro.jpg",
    },
    HB: {
      name: "Dr. Howard Bargman",
      biline: "MD, FRCPC, Dermatologist",
      description: `<p>A member of the Thornhill Dermatology Centre team since its inception in 2009,
        Dr. Howard Bargman is dedicated to providing expert skin care to his patients. With over 35 years of
        practical experience, Dr. Bargman excels in all facets of dermatology. His comprehensive knowledge of
        complex skin conditions makes him a valued asset to the field of dermatology. In addition to his role
        at Thornhill Dermatology Centre, Dr. Bargman performs laser surgery at Sunnybrook Health Sciences
        Centre where he has been the Director of Laser Dermatology since the clinic began in 1999.</p>
        <p>Born and raised in Toronto, Dr. Bargman has stayed close to his Ontario roots. Earning his
        medical degree from the University of Toronto and sharing his residency training between the
        University of Toronto and the University of British Columbia in Vancouver, Dr. Bargman is
        committed to providing expert skin care. Always fascinated by pimples and other irritating
        skin conditions, Dr. Bargman’s goal is to provide answers, treatment and comfort.</p><p>Dr.
        Howard Bargman is an accomplished physician and has published more than 30 articles in peer-
        reviewed scientific journals. Chosen as “Physician of the Year” in 1995 by the Toronto
        Dermatology Society, Dr. Bargman is committed to giving back to the medical community. In
        addition to his role at Sunnybrook Health Science Centre, Dr. Bargman is also an Associate
        Professor of Medicine at the University of Toronto. He loves teaching and has had more than
        200 students and residents study with him in his various offices.  He also volunteers his time
        teaching physicians in Huntsville.  These seminars have recently been accredited for
        continuing medical education by the Northern Ontario School of Medicine.</p><p>Underneath
        his professional demeanour, Dr. Bargman embodies a great sense of humour and keen wit and has
        performed stand-up comedy. A people person, Dr. Bargman drove taxis and worked on garbage
        trucks before completing his Medical Degree. A classical musician, Dr. Bargman played the
        trumpet and was a member of many bands, orchestras and combos before his medical career –
        he even played a few weddings until the night shifts of medical residency came calling. When
        he isn’t practicing or teaching, Dr. Bargman enjoys attending the theatre, playing sports,
        working out and reading.</p>`,
      qualifications: `<p class='my-0 black'><strong>DIPLOMATE OF THE AMERICAN BOARD OF DERMATOLOGY</strong></p><br>
        <p class='my-0 black'><strong>ASSOCIATE PROFESSOR OF DERMATOLOGY</strong></p>
        <p class='my-0'>University of Toronto</p><br>
        <p class="my-0 black"><strong>CERTIFIED AMERICAN BOARD OF LASER MEDICINE</strong></p><br>
        <p class="my-0 black"><strong>DIRECTOR OF LASER, DIVISION OF DERMATOLOGY</strong></p>
        <p class='my-0'>Sunnybrook Health Sciences Centre</p><br>
        <p class="my-0 black"><strong>CERTIFIED MEDICAL LASER SAFETY OFFICER (U.S.)</strong></p>`,
      img: "assets/images/big_bargman.jpg",
    },
    SL: {
      name: "Dr. Sandra Landolt",
      biline: "MD, FRCPC, Dermatologist",
      description: `<p>Joining the team as Dermatology Consultant in 2010, Dr. Sandra Landolt is
        committed to providing expert care to her patients at Thornhill Dermatology Centre. With 22
        years of practical experience, Dr. Landolt shines when assessing and treating complex skin
        conditions.</p><p>Born in Hong Kong and immigrating to Canada at age 12, Dr. Landolt completed
        her undergraduate degree at Queen’s University in Kingston, Ontario. She received her medical
        degree and residency training in both Internal Medicine and Dermatology from the University of
        Toronto. Dr. Landolt was the visiting fellow at UC Irvine in the final year of her residency
        where she spent time focusing on the treatment of psoriasis.</p><p>Dr. Landolt is committed
        to post-graduate medical education in dermatology and apart from moderating and organizing
        educational forums for the dermatology community, she is a key participant in both clinical
        research and education. She is an opinion leader in the treatment of psoriasis, as well as
        the management of complex medical skin disorders. Specializing in complex medical management,
        her interest and dedication is apparent in her ability to treat patients with problems beyond
        their skin issues.</p><p>On a more personal note, Dr. Landolt is an artist and fiction writer.
         She is currently working on multimedia pieces of a graphic novel genre. Her creative
         abilities are also apparent in her visual approach to skin diseases. She views dermatology
         as a branch of visual artistry. Dr. Landolt enjoys following patients through their skin
         journey: from diagnosis to treatment and beyond! Providing the perfect blend of talents in
         medicine and surgery, Dr. Landolt excels within all facets of dermatology.</p><p><strong>Dr. Landolt's professional memberships include the following:</strong>
         </p> <ul><li>Fellow of the Royal College of Physicians and Surgeons of Canada</li><li>Fellow of the American
         Academy of Dermatology</li><li>Member of the Ontario Medical Association</li><li>
         Member of Council of The Toronto Dermatologic Society</li></ul>`,
      qualifications: `<p class='my-0 black'><strong>DERMATOLOGY, INTERNAL MEDICINE</strong></p><br>
      <p class='my-0 black'><strong>PAST PRESIDENT</strong></p>
      <p class='my-0'>Canadian Women in Medicine</p><br>
      <p class='my-0 black'><strong>EXECUTIVE</strong></p>
      <p class='my-0'>Division of Dermatology, Ontario Medical Association</p>`,
      img: "assets/images/big_landolt.jpg",
    },
    JD: {
      name: "Dr. Joel DeKoven",
      biline: "MD, MHSC, FRCPC, Dermatologist",
      description: `<p>With over 25 years of academic and community experience, Dr. Joel DeKoven is dedicated to
        providing quality dermatological care. His diverse training in preventative medicine, public health,
        occupational medicine and dermatology complements the skills of his colleagues.</p><p>In addition to his
        role at Thornhill Dermatology Centre, Dr. DeKoven is a Professor in the Department of Medicine
        at the University of Toronto and is currently a consultant in the Division of Dermatology at Sunnybrook
        Health Sciences Centre, and the Occupational Disease Specialty program at St. Michael’s Hospital. For over
        a decade, he was the Director of the Dermatology Residency Training Program at the University of Toronto
        and is active in Resident training both in Toronto and at the national level.</p><p>Dr. DeKoven earned
        both his undergraduate degree (economics) and medical degree from the University of Manitoba. Following
        the completion of his MD, Dr. DeKoven spent the next two years working full-time as an emergency physician
        in Winnipeg. Furthering his medical education, he completed a Public Health and Preventative Medicine
        residency at the University of Toronto which included a Masters of Health Sciences in Community Health and
        Epidemiology. Dr. DeKoven then became the AIDS physician for the City of Toronto Department of Public Health
        prior to specializing in Dermatology at the University of Toronto.</p><p>Dr. DeKoven is acknowledged as a
        North American expert in the evaluation and treatment of complex allergic and work-related skin disease.
        He is an invited member and current President of the North American Contact Dermatitis Group. Dr. DeKoven
        has co-authored numerous articles in dermatology and has been an invited speaker at many academic meetings
        throughout North America.</p>`,
      qualifications: `<p class='my-0 black'><strong>DERMATOLOGY, PUBLIC HEALTH, PREVENTIVE MEDICINE</strong></p><br>
         <p class='my-0 black'><strong>PROFESSOR OF MEDICINE</strong></p>
         <p class='my-0'>University of Toronto</p><br>
         <p class="my-0 black"><strong>CONSULTANT DERMATOLOGIST</strong></p>
         <p class='my-0'>Sunnybrook Health Sciences Centre </p>
         <p class='my-0'>St. Michael’s Hospital</p><br>
         <p class="my-0 black"><strong>DIPLOMATE OF THE AMERICAN BOARD OF DERMATOLOGY</strong></p><br>`,
      img: "assets/images/big_dekoven.jpg",
    },
    MB: {
      name: "Dr. Michael Brandt",
      biline: "MD, FRCSC, Reconstructive Surgeon",
      description: `<p>Dr. Michael Brandt is our in-house award-winning Facial Plastic and Reconstructive
        Surgeon, known as much for his research achievements as for his dedication to his patients. </p>
        <p>An exceptional academic, Dr. Brandt has received national and international recognition for both
        teaching and research. Over his career, Dr. Brandt has published dozens of peer-reviewed articles in
        various medical journals and is a sought after lecturer.</p><p>Dr. Brandt enjoys the breadth and
        challenge of both cosmetic and reconstructive facial plastic surgery. Using the latest therapies,
        Dr. Brandt aims to restore and enhance his patients' natural beauty to reveal the true you.</p><p>Outside
        of his clinical practice, Dr. Brandt is an Assistant Professor in the University of Toronto’s Department
        of Otolaryngology – Head and Neck Surgery and sits on several national professional committees.
        </p><p><strong>Dr. Brandt's professional memberships include the following:</strong>
         </p> <ul><li>Board Member of the Canadian Academy of Facial Plastic & Reconstructive
         Surgery</li><li>Board Member of the Canadian Society of Otolaryngology - Head & Neck Surgery
         </li><li>Diplomate of the American Board of Facial Plastic & Reconstructive Surgery</li><li>Member of the
         European Academy of Facial Plastic & Reconstructive Surgery</li></ul>`,
      qualifications: `<p class='my-0 black'"><strong>OTOLARYNGOLOGY -
        HEAD & NECK SURGERY, FACIAL PLASTIC & RECONSTRUCTIVE SURGERY</strong></p><br>
        <p class='my-0 black'style="text-transform: capitalize;"><strong>ASSISTANT PROFESSOR</strong></p>
        <p class='my-0'>Division of Facial Plastic & Reconstructive Surgery, Department of Otolaryngology
         - Head & Neck Surgery, Faculty of Medicine, University of Toronto</p><br>`,
      img: "assets/images/big_brandt.jpg",
    },
    ML: {
      name: "Dr. Malika Ladha",
      biline: "MD, FRCPC, Dermatologist",
      description: `<p>Dr. Malika Ladha is committed to bring excellence to medical and cosmetic dermatology. 
      Warm and collaborative relationships are at the heart of her approach to patient care. In addition to medical treatments, 
      Dr. Ladha focuses on achieving optimal skin health and refined cosmetic results.</p>
      
      <p>A rising star in dermatology, she is a double board-certified dermatologist in Canada and the USA. Born and raised in Alberta,
       she completed medical school and dermatology residency at the University of Calgary. She then completed Canada’s only official 
       and prestigious clinical fellowship in Laser Surgery and Aesthetic Dermatology at the University of Toronto.</p>
       
       <p>Dr. Ladha has published multiple articles in peer-reviewed journals. She is an active leader in various medical organizations, 
       including the Canadian Dermatology Association (former Resident Fellow Society co-chairperson), Women’s Dermatologic Society, 
       and the American Society for Laser Medicine and Surgery. Dr. Ladha has been the recipient of various coveted awards, including 
       the 2021 Resident Doctors of Canada Medical Education Award and the 2021 Royal College of Physicians Leadership Award.</p>
       
       <p>Dr. Ladha enjoys reading, writing, swimming, pickleball and spending time with her husband. Follow her on Instagram (<a href="https://www.instagram.com/drmalikaladha">@drmalikaladha</a>) 
       for the latest skin tips and information.</p>
       
       <p><strong>Dr. Ladha's professional memberships include the following:</strong></p>
       <ul>
       <li>Fellow of the Royal College of Physicians and Surgeons</li>
         <li>Fellow of the American Academy of Dermatology</li>
         <li>Member of the Canadian Dermatology Association</li>
         <li>Member of the American Society of Dermatologic Surgery</li>
         <li>Member of the American Society of Laser Medicine and Surgery</li>
         </ul>
       `,
      qualifications: `<p class='my-0 black'><strong>DERMATOLOGY</strong></p>`,
      img: "assets/images/big_ladha.jpg",
    },
    SW: {
      name: "Dr. Sheila Wang",
      biline: "MD, PhD, FRCPC, Dermatologist",
      description: `<p>Dr. Wang is a Canadian board-certified dermatologist. She completed her Ph.D. and M.D. at the University of Toronto,
       her residency in Dermatology at McGill University, and a Royal Society Newton Fellowship at the University of Oxford. Dr. Wang is an Assistant Professor 
       at the University of Toronto and Clinician Investigator.</p>
      
      <p>Dr. Wang has an impressive list of refereed publications and invited talks on clinical innovation and translational science. A committed 
      and compassionate clinician and innovator, Dr. Wang was awarded recently the 2021 Canadian Governor General's Innovation Award, the 2019 
      Canadian Medical Association Young Leader award, the 2019 Joule Innovation Award and the American Academy of Dermatology Quality Improvement Award.</p>
       
       <p><strong>Dr. Wang's professional memberships include the following:</strong></p>
       <ul>
       <li>Fellow of the Royal College of Physicians and Surgeons</li>
       <li>Member of the Canadian Medical Association</li>
       <li>Member of the Canadian Dermatology Association</li>
         </ul>
       `,
      qualifications: `<p class='my-0 black'><strong>DERMATOLOGY</strong></p>`,
      img: "assets/images/wang_tall.jpg",
    },

    // JG: {
    //   name: "Dr. Jill Greenspoon",
    //   biline: "MD, FRCSC, Dermatologist",
    //   description: `<p>Dr. Jill Greenspoon is a proud member of the Thornhill Dermatology Centre, having
    //     joined in November 2018. Dr. Greenspoon takes pride in providing comprehensive and compassionate care to
    //     all her patients. Dr. Greenspoon’s extensive training has shaped her as an expert in all facets of
    //     dermatology.  In particular, Dr. Greenspoon recently completed additional advanced training to refine her
    //     skills in cosmetic and laser dermatology under the guidance of several experts in the cosmetic subspecialty.</p>
    //     <p>Dr. Greenspoon completed both her medical degree and honours bachelor of medical science at the
    //     University of Western Ontario, receiving various scholarships and awards for her leadership, community
    //     service, and academic excellence. Her residency training in Dermatology was completed at the
    //     University of Toronto, where she was recognized for both her clinical and teaching performances.</p>
    //     <p>Dr. Greenspoon is a published author with numerous scientific articles in peer-reviewed journals.
    //     She has given presentations at national and international conferences.  She has a love of teaching,
    //     which is shown in her active involvement in the Dermatology curriculum development and teaching of
    //     medical students and residents at the University of Western Ontario, McMaster University and the
    //     University of Toronto.</p><p>In her free time, Dr. Greenspoon loves spending time with her young family.</p>
    //     <p><strong>Dr. Greenspoon's professional memberships include the following:</strong>
    //     </p> <ul><li>Fellow of the Royal College of Physicians and Surgeons</li>
    //     <li>Member of the Canadian Dermatology Association</li>
    //     <li>Member of the Toronto Dermatologic Society</li>
    //     <li>Member of the American Academy of Dermatology</li></ul>`,
    //   qualifications: "<p class='my-0 black'\"><strong>DERMATOLOGY</strong></p>",
    //   img: "assets/images/big_greenspoon.jpg"
    // },
    // JGe: {
    //   name: "Dr. John Gencarelli",
    //   biline: "MD, FRCSC, Plastic Surgeon",
    //   description: `<p>Dr. John Gencarelli is a Royal College-certified Plastic Surgeon specializing in both cosmetic and
    //     reconstructive surgery of the face and body. As the newest addition to the Thornhill Dermatology Clinic, he is
    //     dedicated to the provision of quality surgical and non-surgical dermatologic care for our patients.</p><p>
    //     Originally from Sault Ste. Marie, Ontario, Dr. Gencarelli obtained his medical degree from the University of Ottawa,
    //      and completed residency training in Halifax, Nova Scotia. Following this, he also pursued a 1-year post-doctoral
    //      fellowship in aesthetic and reconstructive breast surgery at the University of Toronto, where he worked alongside
    //      some of North America’s leading plastic surgeons. Currently, Dr. Gencarelli holds an active associate position at
    //      Mackenzie Health in Vaughan and Richmond Hill where he proudly serves the local community and regional municipality of York.
    //     </p><p>Dr. Gencarelli demonstrates a passion for excellence when it comes to his practice. His approach to surgery is thoughtful,
    //     safe, and meticulous. He prides himself on being compassionate and ensuring that his patients are well-informed, confident in their
    //     decision-making, and comfortable throughout the recovery process. Whether it’s cosmetic or reconstructive surgery, Dr. Gencarelli
    //     is fully committed to achieving the best possible outcome for his patients.</p>
    //     <p><strong>Dr. Gencarelli's professional memberships include the following:</strong></p>
    //     <ul><li>Member of the Royal College of Physicians and Surgeons of Canada (RCPSC)</li>
    //     <li>Member of the Canadian Society of Plastic Surgeons (CSPS)</li>
    //     <li>Member of the Atlantic Society of Plastic Surgeons (ASPS)</li>
    //     <li>Member of the College of Physicians and Surgeons of Ontario (CPSO)</li>
    //     <li>Member of the Ontario Medical Association/Canadian Medical Association (OMA/CMA)</li></ul>`,
    //   qualifications: `<p class='my-0 black'><strong>PLASTIC SURGERY</strong></p><br>
    //      <p class='my-0 black'><strong>ASSOCIATE</strong></p>
    //      <p class='my-0'>Division of Plastic Surgery, Mackenzie Health (Vaughan and Richmond Hill)</p><br>`,
    //   img: "assets/images/Gencarelli.jpg"
    // }
  };
  ngOnInit() {
    const self = this;
    this.route.paramMap.subscribe((param) => {
      self.initials = param.get("initials");
    });
  }
}
